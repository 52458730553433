import { Trans, useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from "react";
import Heading from '../../../shared/Heading';
import Input from '../../../shared/Input';
import SettingsContext from '../../../../contexts/SettingsContext';
import listTrainingProgram from "../../../../data/listTrainingProgram-SAVE-2024";
import {useDispatch} from "../../../../contexts/ResumeContext";
import Dropdown from 'react-select';
import Select from 'react-select';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import './Training.css';



// const Trainings = ({ id }) => {
//   const { t } = useTranslation();
//   const {trainingProgram, setTrainingProgram } = useContext(SettingsContext);
//   const dispatch = useDispatch();
//
//   const handleChangeTrainingProgram = (e) => {
//       const program = e.target.value;
//       const programConfig = listTrainingProgram[program];
//
//       setTrainingProgram(programConfig.nomformation);
//       dispatch({
//           type: 'change_training_program',
//           payload: programConfig,
//       });
//   };
//   return (
//     <section>
//       <Heading id={id} />
//       <Input
//           label={t('builder.settings.trainingProgram')}
//           type="dropdown"
//           options={Object.keys(listTrainingProgram)}
//           value={trainingProgram.nomformation}
//           onChange={handleChangeTrainingProgram}
//       />
//     </section>
//
//
//
//   );
// };
//
// export default memo(Trainings);

const Trainings = ({ id }) => {
  const { t } = useTranslation();
  const { trainingProgram, setTrainingProgram } = useContext(SettingsContext);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const trainingOptions = listTrainingProgram ? Object.keys(listTrainingProgram).map(key => listTrainingProgram[key].nomformation) : [];

  const filterOptions = (searchValue) => {
    const searchTerms = searchValue.toLowerCase().split(' ').filter(term => term.length > 0);
    const filtered = trainingOptions.filter(option =>
      searchTerms.every(term => option.toLowerCase().includes(term))
    );
    setFilteredOptions(filtered);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    filterOptions(newValue);

    const programKey = Object.keys(listTrainingProgram).find(key => listTrainingProgram[key].nomformation === newValue);
    const programConfig = listTrainingProgram[programKey];

    if (programConfig) {
      setTrainingProgram(programConfig.nomformation);
      dispatch({
        type: 'change_training_program',
        payload: programConfig,
      });
    }
  };

  return (
    <section>
      <Heading id={id} />
      <AutoComplete
        style={{ width: '100%' }}
        data={filteredOptions.length > 0 ? filteredOptions : trainingOptions}
        value={inputValue}
        onChange={handleChange}
        onFilterChange={() => {}}
        placeholder={t('builder.settings.selectTrainingProgram')}
      />
    </section>
  );
};

export default Trainings;
