import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useState } from "react";
import * as styles from './Artboard.module.css';
import Button from '../../../components/shared/Button';
import { useSelector } from '../../../contexts/ResumeContext';
import Castform from '../../../templates/Castform';
import Celebi from '../../../templates/Celebi';
import Gengar from '../../../templates/Gengar';
import Glalie from '../../../templates/Glalie';
import Onyx from '../../../templates/Onyx';
import Pikachu from '../../../templates/Pikachu';
import Dracofeu from '../../../templates/Dracofeu';
import Wind from '../../../templates/Wind';
import Stellar from '../../../templates/Stellar';
import Rock from '../../../templates/Rock';
import Light from '../../../templates/Light';
import Line from '../../../templates/Line';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { FaFilePdf } from "react-icons/fa";
import UserContext from "../../../contexts/UserContext";
import { IconContext } from "react-icons";

const Artboard = () => {
  const state = useSelector();
  const { t } = useTranslation();
  const { id, name, metadata } = state;
  const { template } = metadata;
  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);
  const { user } = useContext(UserContext);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImagesLoaded(true);
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
           pdfExportComponent.current.save();
    }
  };


  return (
    <>
      <Helmet>
        <title>
          {name} | {t('shared.appName')}
        </title>
        <link rel="canonical" href={`https://afi24cv.com/app/builder/${id}`} />
      </Helmet>
      <div className="example-config" align="center">
        <IconContext.Provider
          value={{ color: 'red', size: '70px' }}
        >
        <Button icon={FaFilePdf} className="mt-5" onClick={exportPDFWithComponent}>
          {t('modals.export.printDialog.buttonPdf')}
        </Button>
        </IconContext.Provider>
      </div>
      <div ref={container} id="page" className={styles.container}>
        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          margin={0}
          scale={0.75}
          fileName={`${state.profile.lastName}_${state.profile.firstName}_CV_AFi24_${state.metadata.trainingProgram.nomformation}_${new Date().getFullYear()}`}
          author="AFi24"
        >
            {template === 'onyx' && <Onyx data={state} />}
            {template === 'pikachu' && <Pikachu data={state} />}
            {template === 'gengar' && <Gengar data={state} />}
            {template === 'castform' && <Castform data={state} />}
            {template === 'glalie' && <Glalie data={state} />}
            {template === 'celebi' && <Celebi data={state} />}
            {template === 'dracofeu' && <Dracofeu data={state} />}
            {template === 'wind' && <Wind data={state} />}
            {template === 'stellar' && <Stellar data={state} />}
            {template === 'rock' && <Rock data={state} />}
            {template === 'light' && <Light data={state} />}
            {template === 'line' && <Line data={state} />}
        </PDFExport>
        <div style={{backgroundColor:'#808080', position:'absolute', width:'210mm', marginTop:'5px', textAlign:'center'}}>
          <span>↑ Un bon CV se pr&eacute;sente g&eacute;n&eacute;ralement sur une seule page, veillez à ne pas d&eacute;passer cette limite représentant une feuille A4 ↑</span>
        </div>
      </div>
    </>
  );
};

export default memo(Artboard);
