const templateOptions = [
 /* {
    id: 'onyx',
    name: 'Onyx',
  },
  {
    id: 'gengar',
    name: 'Gengar',
  },
  {
    id: 'glalie',
    name: 'Glalie',
  },{
    id: 'celebi',
    name: 'Celebi',
  },*/
  {
    id: 'pikachu',
    name: 'Pikachu',
  },
  {
    id: 'castform',
    name: 'Castform',
  },
  {
    id: 'dracofeu',
    name: 'Dracofeu',
  },
  {
    id: 'stellar',
    name: 'Stellar',
  },{
    id: 'wind',
    name: 'Wind',
  },{
    id: 'rock',
    name: 'Rock',
  },{
    id: 'light',
    name: 'Light',
  },{
    id: 'line',
    name: 'Line',
  }
];

export default templateOptions;
